<template>
  <div class="alert-container">
    <a-alert
      v-for="(item) in activeAlerts" :key="item.id"
      class="sticky-alert"
      :description="item.message"
      type="info"
      closable
      :after-close="item.onClose"
      show-icon/>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import enumerators from '../../mixins/enumerators';

export default {
  name: 'ClusterSamplingAlert',
  mixins: [enumerators()],
  data() {
    return {
      ageGroupMessage: this.$t('components.notifications.ageGroupChangedWarning'),

      samplingMethodMessage: this.$t('components.notifications.samplingMethodChangedWarning'),

      hhPerDayMessage: this.$t('components.notifications.hhPerDayChangedWarning'),

      designEffectMessage: this.$t('components.notifications.designEffectResetWarning'),

      activeAlerts: []
    };
  },
  computed: {
    ...mapState({
      metadata: state => state.survey.metadata,
      formUploadChanged: state => state.surveyForms.formUploadChanged,
      formBuilderChanged: state => state.surveyForms.formBuilderChanged
    })
  },
  watch: {
    metadata: {
      deep: true,
      handler(newValue, oldValue) {
        if (!oldValue) {
          return;
        }

        if (this.enumerators.length !== 0) {
          if (
            (newValue.householdPerDayTeam !== oldValue.householdPerDayTeam ||
            newValue.householdDayTeam !== oldValue.householdDayTeam) && this.getAlertIndex('hhPerDay') === -1
          ) {
            this.activeAlerts.unshift(
              {
                id: 'hhPerDay',
                message: this.hhPerDayMessage,
                onClose: this.closePerDay
              }
            );
          }
          if (newValue.includedAgeGroup !== oldValue.includedAgeGroup && this.getAlertIndex('includedAgeGroup') === -1) {
            this.activeAlerts.unshift(
              {
                id: 'includedAgeGroup',
                message: this.ageGroupMessage,
                onClose: this.closeAgeGroup
              }
            );
          }
          if (newValue.samplingMethod !== oldValue.samplingMethod && this.getAlertIndex('samplingMethod') === -1) {
            this.activeAlerts.unshift(
              {
                id: 'samplingMethod',
                message: this.samplingMethodMessage,
                onClose: this.closeSamplingMethodAlert
              }
            );
          }
        }

        if (newValue.samplingMethod === 'Cluster' && newValue.samplingMethod !== oldValue.samplingMethod && oldValue.samplingMethod != null) {
          this.activeAlerts.unshift(
            {
              id: 'designEffect',
              message: this.designEffectMessage,
              onClose: this.closeDesignEffectAlert
            }
          );
        }
      }
    }
  },
  methods: {
    getAlertIndex(id) {
      const index = this.activeAlerts.findIndex(item => item.id === id);
      return index;
    },
    closePerDay() {
      const index = this.getAlertIndex('hhPerDay');
      if (index > -1) {
        this.activeAlerts.splice(index, 1);
      }
    },
    closeAgeGroup() {
      const index = this.getAlertIndex('includedAgeGroup');
      if (index > -1) {
        this.activeAlerts.splice(index, 1);
      }
    },
    closeSamplingMethodAlert() {
      const index = this.getAlertIndex('samplingMethod');
      if (index > -1) {
        this.activeAlerts.splice(index, 1);
      }
    },
    closeDesignEffectAlert() {
      const index = this.getAlertIndex('designEffect');
      if (index > -1) {
        this.activeAlerts.splice(index, 1);
      }
    }
  }
};
</script>
<style lang="scss" scoped>

.sticky-alert {
  margin-top: 10px;
  align-content: center;
  min-width: 70%;
  width: auto;
  max-width: 100%;
  margin-right: 24pt;
}

.alert-container {
  position: fixed;
  z-index: 9999;
  width: auto;
}
</style>
